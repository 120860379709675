/* eslint-disable import/exports-last */
import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../../components/floating-button/floating-button';
import Layout from '../../../../components/layout';
import React from 'react';

const PayoutsPage = (props) => {
    const components = props.data.cms.payoutsPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }
    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components}></DynamicZone>
        </Layout>
    );
};
export default PayoutsPage;

export const query = graphql`
    query ($locale: String!) {
        cms {
            payoutsPageTemplate(locale: $locale) {
                components {
                    ... on STRAPI_ComponentComponentsHeader {
                        __typename
                        backgroundImage {
                            url
                        }
                        title
                        headerSubtitle: subtitle
                        primaryImage {
                            url
                        }
                        contentOffset
                        mobileContentOffset
                        backgroundImageHeight
                    }
                    ... on STRAPI_ComponentUseCasesUseCasesSection {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        sectionTitle {
                            tailwindClasses
                            style
                            description
                            title
                            ytLink
                        }
                        UseCasesColumns {
                            title
                            subtitle
                            icon {
                                url
                            }
                            backgroundImage {
                                url
                            }
                        }
                    }
                    ... on STRAPI_ComponentComponentsHorizontalScroll {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        sectionTitle {
                            tailwindClasses
                            title
                            ytLink
                            style
                            description
                        }
                        horizontalScrollColor
                        hasMobileGrayBackground
                        imagesTailwindClasses
                        mobileCardTailwindClasses
                        HorizontalScrollBenefits {
                            title
                            subtitle
                            image {
                                url
                            }
                        }
                    }
                    ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        sectionTitle {
                            tailwindClasses
                            title
                            ytLink
                            description
                            style
                        }
                        tabHeight
                        tabs {
                            images {
                                url
                            }
                            imagesMetadata
                            imagesTitles
                            isLabelImageVisibleOnDesktop
                            label
                            labelColour
                            scrollColor
                            labelImage {
                                url
                            }
                            link
                            subtitle
                            title
                            type
                        }
                        tabsDarkBackground
                    }
                    ... on STRAPI_ComponentComponentsOffsetImageWithList {
                        __typename
                        topPusherHeight
                        topPusherHeightMobile
                        isImageVisibleOnMobile
                        title
                        imageType
                        imageOffset
                        imageHeight
                        isReverted
                        image {
                            url
                        }
                        ImageWithText {
                            content
                            image {
                                url
                            }
                        }
                    }
                    ... on STRAPI_ComponentSeoSeo {
                        __typename
                        seoDescription
                        seoLink
                        seoTitle
                        seoImages {
                            url
                        }
                    }
                    ... on STRAPI_ComponentComponentsSingleImage {
                        __typename
                        image {
                            url
                        }
                        topPusherHeight
                        topPusherHeightMobile
                        singleImageContent
                    }
                }
            }
        }
    }
`;
